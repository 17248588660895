
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentVehicle:false,
  showForm:false,
  sweetInfoDelete:false,
  qr_codes:[],
  cubicCapacities:[],
  cubicCapacity:false
}

const VehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VEHICLES_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_VEHICLES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentVehicle:false
      }

    case "INIT_FORM_VEHICLE":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_VEHICLE":
      return {...state,
        currentVehicle:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }
    
    case "ERROR_GET_VEHICLE":
      return {...state,...action}
    
    case "ERROR_DELETE_VEHICLE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_VEHICLE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_VEHICLE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_VEHICLE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_VEHICLE":
      return {
        ...state,
        sweet:null
      }
    case "HIDE_SWEET_VEHICLE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "HIDE_SHOW_VEHICLE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
        currentVehicle:false,
        showForm:false        
      }

    /***********************************************************
    * Cubic Capacities    
    */

    case "GET_CUBIC_CAPACITIES_LIST":
      return {
        ...state,
        cubicCapacities: action.data,        
      }

    case "SAVED_CUBIC_CAPACITY":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
    
    case "HIDE_SWEET_CUBIC_CAPACITY":
      return {
        ...state,
        sweet:null
      }
    case "ERROR_SAVED_CUBIC_CAPACITY":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_CUBIC_CAPACITY":
      return {
        ...state,
        cubicCapacity: action.cubicCapacity,
        sweetInfoDelete:false,
        showForm:true
      }

      case "HIDE_SHOW_CUBIC_CAPACITY":
        return {
          ...state,
          sweet:null,
          sweetInfoDelete:false,
          cubicCapacity:false,
          showForm:false        
        }
        
      case "CLEAR_CUBIC_CAPACITY":
        return {
          ...state,
          cubicCapacity:false,
        }

    
    default:
      return state
  }
}

export default VehiclesReducer