const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentRole:false,
  showForm:false,
  sweetInfoDelete:false,
  dataPermissions:[],
}

const RolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROLES_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_ROLES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentRole:false
      }

    case "GET_ROLES_PERMISSIONS_ALL":
      return {
        ...state,
        dataPermissions: action.data
      }
    
    case "INIT_FORM_ROLE":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_ROLE":
      return {...state,
        currentRole:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }
    
    case "ERROR_GET_ROLE":
      return {...state,...action}
    
    case "ERROR_DELETE_ROLE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_ROLE":
      return {
        ...state,
        response:{...action.res},
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_ROLE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_ROLE":
      return {
        ...state,
        sweet:null
      }
    case "HIDE_SWEET_ROLE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    
    default:
      return state
  }
}

export default RolesReducer