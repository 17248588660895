
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentMachinery:false,
  showForm:false,
  sweetInfoDelete:false,
  listTypesMachineries:[],
  listOperators:[],
  listRouters:[],
  listMaterials:[],
  listInputMaterials:[],
  listConfigs:[],
  listSelectConfigs:[],
}

const MachineriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MACHINERIES_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_MACHINERIES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentMachinery:false
      }

    case "GET_MACHINERY_TYPESMACHINERIES_ALL":
      return {
        ...state,
        listTypesMachineries: action.data
      }



    case "GET_MACHINERY_MACHINERIESOPERATORS_ALL":
      return {
        ...state,
        listOperators: action.data
      }


    case "GET_MACHINERY_MACHINERIESROUTERS_ALL":
        return {
          ...state,
          listRouters: action.data
        }

    case "GET_MACHINERY_MATERIALS_ALL":
          return {
            ...state,
            listMaterials: action.data
          }

    case "GET_MACHINERY_INPUT_MATERIALS_ALL":
      return {
        ...state,
        listInputMaterials: action.data
      }

    case "GET_MACHINERY_CONFIGS_ALL":
      return {
        ...state,
        listConfigs: action.data
      }

    case "GET_MACHINERY_CONFIGS_SELECT_ALL":
      return {
        ...state,
        listSelectConfigs: action.data
      }

      

      

    case "INIT_FORM_MACHINERY":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_MACHINERY":
      return {...state,
        currentMachinery:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "ERROR_GET_MACHINERY":
      return {...state,...action}

    case "ERROR_DELETE_MACHINERY":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_DELETE_MACHINERY_OUTPUT_MATERIAL":
        return {
          ...state,
          sweetInfoDelete:{...action.sweet}
        }

    case "ERROR_DELETE_MACHINERY_CONFIG":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_ACTIVE_MACHINERY_CONFIG":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_INACTIVE_MACHINERY_CONFIG":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

      

    

    case "SAVED_MACHINERY":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SAVED_MACHINERY_CONFIG":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

      

    case "ERROR_SAVED_MACHINERY":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_MACHINERY_CONFIG":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

      

    case "SHOW_SWHEET_MACHINERY":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_MACHINERY":
      return {
        ...state,
        sweet:null
      }
    case "HIDE_SWEET_MACHINERY":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

      case "HIDE_SWHEET_MACHINERY_CONFIG":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "HIDE_SWEET_MACHINERY_OUTPUT_MATERIAL":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }


  
    case "HIDE_SWHEET_MACHINERY_MATERIAL":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "HIDE_SWHEET_MACHINERY_OUTPUT_MATERIAL":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }






    default:
      return state
  }
}

export default MachineriesReducer
