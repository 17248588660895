import * as constants from '../../../constants';

import axios from 'axios';

import { history } from "../../../../history"


axios.interceptors.response.use(response => {
  return response;
}, error => {
  
 if ( error.response !== undefined && error.response.status === 401) {
  console.log('Error de no autorizado:',error.response.status );
  history.push("/login");
  localStorage.clear();
 } else {
  return Promise.reject(error);
 }
});

export const getData = params => {
  return async dispatch => {
    
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

    await axios.get(constants.BASE_URL+'expenses', {params}).then(response => {
      dispatch({
        type: "GET_REPORT_EXPENSES_DATA",
        data: response.data.expenses,
        totals:response.data.totals,
        totalPages:response.data.expenses.last_page,
        totalRecords:response.data.expenses.total,
        params
      })
    })
  }
}

export const downloadData = params => {
  return async dispatch => {
    
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

    await axios.request({
      url:constants.BASE_URL+'report/expenses/download',
      method:'GET',
      responseType:'blob',
      params:params
    })
    .then(response => {
      console.log("=>",response);
      const fileName = response.headers['report-filename'];
      console.log("=>",fileName);
      const downloadUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
  }
}


export const getInitialData = () => {
  return async dispatch => {

    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};

    await axios.get(constants.BASE_URL+'expenses',{type:1}).then(response => {
      dispatch({ type: "GET_REPORT_EXPENSES_ALL_DATA", data:response.data.expenses, totals:response.data.totals })
    })
  }
}


export const getExpenseTypes = () => {
  return async dispatch => {
    const data = {isLoading:true, msg:"Cargando lista de categorías de gastos..."};
    dispatch({type: "SHOW_REPORT_LOADING",data:data,index:"expenses"});
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    await axios.get(constants.BASE_URL+'types_expenses',{params:{ page: 1, perPage: 100, status:1 }}).then(response => {
      let expenseTypes = response.data.typesExpenses.data.map(e =>{ return {"value":e.id,"label":e.name}});
      expenseTypes.push({"value":"","label":"Todas"});
      dispatch({ type: "GET_REPORT_EXPENSES_TYPES_ALL", data: expenseTypes });
      dispatch({type: "HIDE_REPORT_LOADING",data:{},index:"expenses"});
    })
  }
}


export const getBussinessUnits = ()=> {

  return async dispatch => {
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    await axios.get(constants.BASE_URL+'bussiness_units',{params:{page: 1,perPage: 100}}).then(response => {

      const listBussinessUnits = [{"value":"","label":"-- TODAS --"}];
      const temp = response.data.bussinessUnits.data.map(e =>{ return {"value":e.id,"label":e.name} });
      listBussinessUnits.push(...temp);

      dispatch({ type: "GET_EXPENSES_REPORT_BUSSINESS_UNITS_ALL", data: listBussinessUnits })
    })
  }
}


export const getPaymentMethods = () => {

  return async dispatch => {
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    await axios.get(constants.BASE_URL+'payment_types',{params:{ page: 1, perPage: 1000, status:1 }}).then(response => {
      
      const payment_types = [{"value":"","label":"-- TODAS --"}];
      const temp = response.data.paymentTypes.data.map(e =>{ return {"value":e.id,"label":e.name} });
      payment_types.push(...temp);
      
      
      dispatch({ type: "GET_EXPENSES_REPORT_PAYMENT_TYPES_ALL", data: payment_types });
      
    })
  }

}


export const getVehicles = () => {

  return async dispatch => {
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    await axios.get(constants.BASE_URL+'vehicles',{params:{ page: 1, perPage: 1000, status:1 }}).then(response => {
      
      const transportVehicles = [{"value":"","label":"-- TODOS --"}];
      const temp = response.data.transportVehicles.data.map(e =>{ return {"value":e.id,"label":e.registration_plate} });
      transportVehicles.push(...temp);
      
      
      dispatch({ type: "GET_EXPENSES_REPORT_VEHICLES_TYPES_ALL", data: transportVehicles });
      
    })
  }

}

export const getMachineries = () => {

  return async dispatch => {
    let userData = localStorage.getItem('USER_INFO');
    let token = JSON.parse(userData).token;
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

    await axios.get(constants.BASE_URL+'machineries',{params:{ page: 1, perPage: 1000, status:1 }}).then(response => {
      
      const machineries = [{"value":"","label":"-- TODAS --"}];
      const temp = response.data.heavyMachineries.data.map(e =>{ return {"value":e.id,"label":e.name} });
      machineries.push(...temp);
      
      
      dispatch({ type: "GET_EXPENSES_REPORT_MACHINERIES_ALL", data: machineries });
      
    })
  }

}