
const initialState = {
  data: [],
  dataExpenses: [],
  dataPayments: [],
  dataContractPayments: [],
  params: null,
  allData: [],
  totals: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
}

const CashFlowReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "GET_REPORT_CASHFLOW_DATA":
      return {
        ...state,
        data: action.data.data,
        dataExpenses: action.dataExpenses,
        dataPayments: action.dataPayments,
        dataContractPayments: action.dataContractPayments,
        totals: action.totals,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_REPORT_CASHFLOW_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totals: action.totals,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentSale:false
      }
    
    default:
      return state
  }
}

export default CashFlowReducer