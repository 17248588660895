const initialState = {
  listClients:[],
  listMaterials:[],
  listTickets:[],
  sweet:null,
}

const CertificatesReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "GET_CERTIFICATES_CLIENTS_ALL":
      return {
        ...state,
        listClients: action.data
      }

    case "GET_CERTIFICATES_MATERIALS_ALL":
      return {
        ...state,
        listMaterials: action.data,
      }

    case "GET_CERTIFICATES_SALES_ALL":
      return {
        ...state,
        listTickets: action.data,
      }

    case "SHOW_SWHEET_CERTIFICATES":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_CERTIFICATES":
      return {
        ...state,
        sweet:null
      }




    case "CLEAR_CONTRACT_MATERIALS_DATA":
      return {
        ...state,
        contractMaterials: []
      }
    
    case "SHOW_CONTRACT_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_CONTRACT_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }
    
    
    case "GET_CONTRACTS_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

   

    case "GET_CONTRACT_PAYMENTS_DATA":
      return {
        ...state,
        contractPayments: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_CONTRACT_DOCUMENTS_DATA":
      return {
        ...state,
        contractDocuments: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

      

    case "GET_CONTRACT_PAYMENT_TYPE_ALL":
      return {
        ...state,
        listPaymentTypes: action.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

        

    case "UPDATE_MATERIAL_CONTRACT_VOLUMEN":
      return {
        ...state,
        contractMaterials: action.data
      }
        

      case "GET_CONTRACT_VEHICLES_DATA":
        return {
          ...state,
          contractVehicles: action.data.data,
          totalPages: action.totalPages,
          params: action.params,
          sortIndex: [action.data.from,action.data.to]
        }

      case "GET_CONTRACT_USERS_DATA":
        return {
          ...state,
          contractUsers: action.data.data,
          totalPages: action.totalPages,
          params: action.params,
          sortIndex: [action.data.from,action.data.to]
        }

        

      

    case "GET_CONTRACTS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentContract:false
      }

      
      
      case "GET_CONTRACT_VEHICLES_ALL":
        return {
          ...state,
          listVehicles: action.data
        }

      case "GET_CONTRACT_USERS_ALL":
          return {
            ...state,
            listUsers: action.data
          }
      
      case "GET_CONTRACT_MATERIALS_ALL":
        return {
          ...state,
          listMaterials: action.data
        }

      case "GET_CONTRACT_MACHINERIES_ALL":
        return {
          ...state,
          listMachineries: action.data
        }

    

    case "INIT_FORM_CONTRACT":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
        sweetInfoActive:false,
        sweetInfoInactive:false,
      }

    case "SHOW_CONTRACT":
      return {...state,
        currentContract:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
        sweetInfoActive:false,
        sweetInfoInactive:false,
      }

    case "GET_CONTRACT_PROGRESS_DATA":
      return {...state,
        contractProgress:{...action.data},
      }

      
    
    case "ERROR_GET_CONTRACT":
      return {...state,...action}
    
    case "ERROR_DELETE_CONTRACT":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_ACTIVE_CONTRACT":
      return {
        ...state,
        sweetInfoActive:{...action.sweet}
      }

    case "ERROR_INACTIVE_CONTRACT":
      return {
        ...state,
        sweetInfoInactive:{...action.sweet}
      }

    case "SAVED_CONTRACT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SAVED_CONTRACT_MATERIAL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SAVED_CONTRACT_PAYMENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
    
    case "SAVED_CONTRACT_DOCUMENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
      

    case "SAVED_CONTRACT_VEHICLE":
        return {
          ...state,
          sweet:{ ...action.sweet}
        }

    case "SAVED_CONTRACT_USER":
          return {
            ...state,
            sweet:{ ...action.sweet}
          }

    case "ERROR_SAVED_CONTRACT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_CONTRACT_MATERIAL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_CONTRACT_PAYMENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    
    case "ERROR_SAVED_CONTRACT_DOCUMENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }


    case "ERROR_UPDATE_CONTRACT_MATERIAL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

      

    case "ERROR_SAVED_CONTRACT_VEHICLE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
    
    case "ERROR_SAVED_CONTRACT_USER":
        return {
          ...state,
          sweet:{ ...action.sweet}
        }

    
    case "HIDE_SWHEET_CONTRACT_MATERIAL":
      return {
        ...state,
        sweet:null
      }
      

    case "HIDE_SWHEET_CONTRACT_PAYMENT":
      return {
        ...state,
        sweet:null
      }

    
    case "HIDE_SWHEET_CONTRACT_DOCUMENT":
      return {
        ...state,
        sweet:null
      }


    case "HIDE_SWHEET_CONTRACT_VEHICLE":
      return {
        ...state,
        sweet:null
      }

    case "HIDE_SWHEET_CONTRACT_USER":
        return {
          ...state,
          sweet:null
        }
      
    case "HIDE_SWEET_CONTRACT":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
        sweetInfoActive:false,
        sweetInfoInactive:false
      }

    case "CLEAN_CONTRACT_LOADING":
      return {
        ...state,
        dataLoading:{}
      }

      

    
    default:
      return state
  }
}

export default CertificatesReducer