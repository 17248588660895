import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "home",
    title: "Inicio",
    type: "tiem",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "users_home",
    title: "Gestión de Usuarios",
    type: "dropdown",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/user_management",
    children: [
      {
        id: "users",
        title: "Usuarios",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/user_management/users",
        permissions: ["admin", "editor"]
      },
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/user_management/roles",
        permissions: ["admin", "editor"]
      },
      
    ]
  },
  {
    id: "materials_home",
    title: "Materiales",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/material_management/materials",
  }  
]

export default horizontalMenuConfig
