const initialState = {
  data: [],
  params: null,
  allData: [],
  totals: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentSale:false,
  showForm:false,
  sweetInfoDelete:false,
  listContracts:[],
  dataLoading:{}
}

const ContractIndicatorsReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "SHOW_SALE_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_SALE_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }

      case "GET_REPORT_CONTRACT_INDICATORS_CONTRACTS_ALL":
        return {
          ...state,
          listContracts: action.data
        }

    case "SHOW_SALE":
      return {...state,
        currentSale:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_SWHEET_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_SALE":
      return {
        ...state,
        sweet:null
      }
      
    case "HIDE_SWEET_SALE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }
    
    default:
      return state
  }
}

export default ContractIndicatorsReducer