
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentClient:false,
  showForm:false,
  sweetInfoDelete:false,
}

const ClientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLIENTS_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_CLIENTS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentClient:false
      }

    case "INIT_FORM_CLIENT":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_CLIENT":
      return {...state,
        currentClient:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }
    
    case "ERROR_GET_CLIENT":
      return {...state,...action}
    
    case "ERROR_DELETE_CLIENT":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_CLIENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_CLIENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_CLIENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_CLIENT":
      return {
        ...state,
        sweet:null
      }
    case "HIDE_SWEET_CLIENT":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    
    default:
      return state
  }
}

export default ClientsReducer