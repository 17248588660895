import { getPaymentMethods } from "../../../actions/reports/expenses/ExpensesActions";

const initialState = {
  data: [],
  params: null,
  allData: [],
  totals: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentSale:false,
  showForm:false,
  sweetInfoDelete:false,
  listExpenseTypes:[],
  bussinessUnits:[],
  paymentMethods:[],
  vehicles:[],
  machineries:[],
  dataLoading:{}
}

const ExpensesReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "SHOW_SALE_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_SALE_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }
    
    case "GET_REPORT_EXPENSES_DATA":
      return {
        ...state,
        data: action.data.data,
        totals: action.totals,
        totalPages: action.totalPages,
        totalRecords:action.totalRecords,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_REPORT_EXPENSES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totals: action.totals,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentSale:false
      }

      case "GET_REPORT_EXPENSES_TYPES_ALL":
        return {
          ...state,
          listExpenseTypes: action.data
        }

      case "GET_EXPENSES_REPORT_BUSSINESS_UNITS_ALL":
        return {
          ...state,
          bussinessUnits: action.data
        }

      case "GET_EXPENSES_REPORT_PAYMENT_TYPES_ALL":
        return {
          ...state,
          paymentMethods: action.data
        }

      case "GET_EXPENSES_REPORT_VEHICLES_TYPES_ALL":
          return {
            ...state,
            vehicles: action.data
          }

      case "GET_EXPENSES_REPORT_MACHINERIES_ALL":
        return {
          ...state,
          machineries: action.data
        }

    default:
      return state
  }
}

export default ExpensesReducer