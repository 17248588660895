const initialState = {
  directSalesOptions:{},
  directSalesData:[],
  
  contractSalesOptions:{},
  contractSalesData:[],
  
  directMachineriesOptions:{},
  directMachineriesData:[],
  
  contractMachineriesOptions:{},
  contractMachineriesData:[],

  fuelMachineriesOptions:{},
  fuelMachineriesData:[],

  directMaterialsOptions:{},
  directMaterialsData:[]
}

const homeDashboardReducer = (state = initialState, action) => {
  
  switch (action.type) {
    
    case "GET_DASHBOARD_DIRECT_SALES_DATA":
      return {
        ...state,
        directSalesData:action.data,
        directSalesOptions:action.options
      }

    case "GET_DASHBOARD_CONTRACT_SALES_DATA":
      return {
        ...state,
        contractSalesData:action.data,
        contractSalesOptions:action.options
      }

    case "GET_DASHBOARD_SALE_MACHINERIES_DATA":
      return {
        ...state,
        directMachineriesData:action.data,
        directMachineriesOptions:action.options
      }

    case "GET_DASHBOARD_CONTRACT_MACHINERIES_DATA":
      return {
        ...state,
        contractMachineriesData:action.data,
        contractMachineriesOptions:action.options
      }

    case "GET_DASHBOARD_FUEL_MACHINERIES_DATA":
      return {
        ...state,
        fuelMachineriesData:action.data,
        fuelMachineriesOptions:action.options
      }

    case "GET_DASHBOARD_SALE_MATERIALS_DATA":
      return {
        ...state,
        directMaterialsData:action.data,
        directMaterialsOptions:action.options
      }

    default:
      return state
  }
}

export default homeDashboardReducer