const initialState = {
  data: [],
  params: null,
  allData: [],
  totals: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  showForm:false,
  sweetInfoDelete:false,
  dataLoading:{}
}

const ContractsReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "SHOW_CONTRACTS_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_CONTRACTS_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }
    
    case "GET_REPORT_CONTRACTS_DATA":
      return {
        ...state,
        data: action.data,
        params: action.params,
        
      }

    case "GET_REPORT_CONTRACTS_ALL_DATA":
      return {
        ...state,
        allData: action.data,        
        showForm:false,
      }
      
    default:
      return state
  }
}

export default ContractsReducer