
const initialState = {
  data: [],
  params: null,
  allData: [],
  totals: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentSale:false,
  showForm:false,
  sweetInfoDelete:false,
  listMachinery:[],
  dataLoading:{}
}

const MachineryReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "SHOW_SALE_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_SALE_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }
    
    case "GET_REPORT_MACHINERY_DATA":
      return {
        ...state,
        data: action.data.data,
      }

    case "GET_REPORT_MACHINERY_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totals: action.totals,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentSale:false
      }

      case "GET_REPORT_MACHINERY_ALL":
        return {
          ...state,
          listMachinery: action.data
        }
    default:
      return state
  }
}

export default MachineryReducer