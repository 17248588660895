
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentBankAccount:false,
  showForm:false,
  sweetInfoDelete:false,
  listBanks:[],
  dataLoading:{},
}

const bankAccountsReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case "GET_BANK_ACCOUNTS_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to],
      }

    case "GET_BANK_ACCOUNTS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        currentBankAccount:false
      }
    
    case "SHOW_BANK_ACCOUNT_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading,
        showForm:false,
    }

    case "HIDE_BANK_ACCOUNT_LOADING":
      delete state.dataLoading[action.index];
      return {
        ...state
      }

    case "SHOW_BANK_ACCOUNT":
      return {...state,
        currentBankAccount:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "ERROR_GET_BANK_ACCOUNT":
      return {...state,...action}

    case "CLEAR_BANK_ACCOUNT_BANKS_ALL":
      return {
        ...state,
        listBanks: []
      }

    case "GET_BANK_ACCOUNT_BANKS_ALL":
      return {
        ...state,
        listBanks: action.data
      }

    case "SAVED_BANK_ACCOUNT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
    
    case "HIDE_SWHEET_BANK_ACCOUNT":
      return {
        ...state,
        sweet:null
      }

    case "ERROR_SAVED_BANK_ACCOUNT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
    
    case "HIDE_SWEET_BANK_ACCOUNT":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "ERROR_DELETE_BANK_ACCOUNT":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_ACTIVE_BANK_ACCOUNT":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_INACTIVE_BANK_ACCOUNT":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "INIT_FORM_BANK_ACCOUNT":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_SWHEET_BANK_ACCOUNT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    default:
      return state
  }
}

export default bankAccountsReducer