import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: [],
    navLink: "/",
  },
  {
    id: "users_home",
    title: "Accesos",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    permissions: ["USER_MANAGEMENT_LIST", "ROLES_MANAGEMENT_LIST"],
    navLink: "/user_management",
    children: [
      {
        id: "users",
        title: "Usuarios",
        type: "item",
        icon: <Icon.UserPlus size={17} />,
        navLink: "/user_management/users",
        permissions: ["USER_MANAGEMENT_LIST"]
      },
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <Icon.Inbox size={17} />,
        navLink: "/user_management/roles",
        permissions: ["ROLES_MANAGEMENT_LIST"]
      }
    ]
  },
  {
    id: "materials_home",
    title: "Materiales",
    type: "item",
    icon: <Icon.Box size={20} />,
    permissions: ["MATERIALS_MANAGEMENT_LIST"],
    navLink: "/material_management/materials",
  } ,
  {
    id: "clients_home",
    title: "Clientes",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ["CLIENTS_MANAGEMENT_LIST"],
    navLink: "/client_management/clients",
  },

  {
    id: "vehicles_home",
    title: "Transportadores",
    type: "item",
    icon: <Icon.Truck size={20} />,
    permissions: ["VEHICLES_MANAGEMENT_LIST"],
    navLink: "/vehicle_management/vehicles",
  },

  {
    id: "bank_account_home",
    title: "Cuentas",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["BANK_ACCOUNTS_MANAGEMENT_LIST"],
    navLink: "/bank_accounts_management/bank_accounts",
  },

  {
    id: "cellar_home",
    title: "Bodegas",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["CELLARS_MANAGEMENT_LIST"],
    navLink: "/cellar_management/cellars",
  },

  {
    id: "material_transfer_home",
    title: "Transferencias",
    type: "item",
    icon: <Icon.Box size={20} />,
    permissions: ["MATERIAL_TRANSFERS_MANAGEMENT_LIST"],
    navLink: "/material_transfer_management/material_transfers",
  },

  {
    id: "machinery_home",
    title: "Maquinarias",
    type: "collapse",
    icon: <Icon.Cpu size={20} />,
    permissions: ["MACHINERIES_MANAGEMENT_LIST", "FUELS_MANAGEMENT_LIST","MAINTENANCES_MANAGEMENT_LIST"],
    navLink: "/machinery_management/machineries",
    children: [
      {
        id: "machineries",
        title: "Equipos",
        type: "item",
        icon: <Icon.Truck size={17} />,
        navLink: "/machinery_management/machineries",
        permissions: ["MACHINERIES_MANAGEMENT_LIST"]
      },
      {
        id: "fuels",
        title: "Combustible",
        type: "item",
        icon: <Icon.Database size={17} />,
        navLink: "/machinery_management/fuels",
        permissions: ["FUELS_MANAGEMENT_LIST"]
      },
      {
        id: "Maintenances",
        title: "Mantenimientos",
        type: "item",
        icon: <Icon.Settings size={17} />,
        navLink: "/machinery_management/maintenances",
        permissions: ["MAINTENANCES_MANAGEMENT_LIST"]
      }
    ]
  },

  {
    id: "sales_home",
    title: "Ventas",
    type: "item",
    icon: <Icon.TrendingUp size={20} />,
    permissions: ["SALES_MANAGEMENT_LIST"],
    navLink: "/sales_management/sales",
  },

  {
    id: "prepaid_sales",
    title: "Preventas",
    type: "item",
    icon: <Icon.TrendingUp size={20} />,
    permissions: ["PREPAID_SALES_MANAGEMENT_LIST"],
    navLink: "/prepaid_management/prepaid_sales",
  },

  {
    id: "contracts_home",
    title: "Contratos",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["CONTRACTS_MANAGEMENT_LIST"],
    navLink: "/contract_management/contracts",
  },

  {
    id: "expenses_home",
    title: "Gastos",
    type: "item",
    icon: <Icon.TrendingDown size={20} />,
    permissions: ["EXPENSES_MANAGEMENT_LIST"],
    navLink: "/expenses_management/expenses",
  },

  {
    id: "reports_home",
    title: "Reportes",
    type: "collapse",
    icon: <Icon.FileText size={20} />,
    permissions: ["REPORTS_MANAGEMENT_SHOW_SALES", "REPORTS_MANAGEMENT_SHOW_CONTRACT_INDICATORS","REPORTS_MANAGEMENT_SHOW_EXPENSES"],
    navLink: "/reports",
    children: [
      {
        id: "report_cash_flow_home",
        title: "Flujo de Caja",
        type: "item",
        icon: <Icon.TrendingUp size={17} />,
        navLink: "/reports/cash_flow",
        permissions: ["REPORTS_MANAGEMENT_SHOW_CASH_FLOW"]
      },
      {
        id: "report_sales_home",
        title: "Ventas",
        type: "item",
        icon: <Icon.TrendingUp size={17} />,
        navLink: "/reports/sales",
        permissions: ["REPORTS_MANAGEMENT_SHOW_SALES"]
      },
      /*{
        id: "report_contracts_home",
        title: "V. por Contrato",
        type: "item",
        icon: <Icon.Briefcase size={17} />,
        navLink: "/reports/contract_sales",
        permissions: ["admin", "editor"]
      },*/
      {
        id: "report_contracts_indicators_home",
        title: "Ind. de Contrato",
        type: "item",
        icon: <Icon.Grid size={17} />,
        navLink: "/reports/contract_indicators",
        permissions: ["REPORTS_MANAGEMENT_SHOW_CONTRACT_INDICATORS"]
      },
      {
        id: "report_contracts_home",
        title: "Contratos",
        type: "item",
        icon: <Icon.Grid size={17} />,
        navLink: "/reports/contracts",
        permissions: ["REPORTS_MANAGEMENT_SHOW_CONTRACTS"]
      },
      {
        id: "report_expenses_home",
        title: "Gastos",
        type: "item",
        icon: <Icon.TrendingDown size={17} />,
        navLink: "/reports/expenses",
        permissions: ["REPORTS_MANAGEMENT_SHOW_EXPENSES"]
      },
      {
        id: "report_machinery_home",
        title: "Maquinaria",
        type: "item",
        icon: <Icon.Truck size={17} />,
        navLink: "/reports/machinery",
        permissions: ["REPORTS_MANAGEMENT_SHOW_MACHINERY"]
      },
      {
        id: "report_certificates_home",
        title: "Certificados",
        type: "item",
        icon: <Icon.FileText size={20} />,
        navLink: "/reports/certificates",
        permissions: ["REPORTS_MANAGEMENT_SHOW_CERTIFICATES"]
      }
    ]
  },


]

export default navigationConfig
