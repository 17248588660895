const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentMaterialTransfert:false,
  showForm:false,
  sweetInfoDelete:false,
  originList: [],
  destinationList: [],
  machineryList: [],
  materialList: [],
  vehicleList: [],
  routerList: [],
  configList: [],
  totals:{},
  materialTransfertPrices:[],
  materialTransfertPrice:{},
  price:0,  
  priceOriginList: [],
  priceDestinationList: [],
  dataLoading:{},
}

const MaterialTransfersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MATERIAL_TRANSFERS_DATA":
      return {
        ...state,
        data: action.data.data,
        totals: action.totals,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_MATERIAL_TRANSFERS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentMaterialTransfert:false
      }

    
    case "GET_MATERIAL_TRANSFER_PRICES_DATA":
      return {
        ...state,
        materialTransfertPrices: action.data,
      }
    
    case "GET_MATERIAL_TRANSFER_PRICE_DATA":
      return {
        ...state,
        materialTransfertPrice: action.data
      }
    
    case "GET_MATERIAL_TRANSFER_ONLY_PRICE_DATA":
      return {
        ...state,
        price: action.data
      }

      

    case "SAVED_MATERIAL_TRANSFER_PRICE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_MATERIAL_TRANSFER_PRICE":
      return {
        ...state,
        sweet:null
      }

    case "ERROR_SAVED_MATERIAL_TRANSFER_PRICE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_DELETE_MATERIAL_TRANSFER_PRICE":
      return {
        ...state,
        sweet:{...action.sweet}
      }
      

    case "GET_MATERIAL_TRANSFER_ORIGIN_ALL":
      return {
        ...state,
        originList: action.data
      }
    
    case "GET_MATERIAL_TRANSFER_PRICE_ORIGIN_ALL":
      return {
        ...state,
        priceOriginList: action.data
      }

    case "GET_MATERIAL_TRANSFER_DESTINATION_ALL":
      return {
        ...state,
        destinationList: action.data
      }

    case "GET_MATERIAL_TRANSFER_PRICE_DESTINATION_ALL":
      return {
        ...state,
        priceDestinationList: action.data
      }
      

    case "GET_MATERIAL_TRANSFERS_MACHINERIES_ALL":
        return {
          ...state,
          machineryList: action.data
        }

    case "GET_MATERIAL_TRANSFERS_MATERIALS_ALL":
          return {
            ...state,
            materialList: action.data
          }

    case "GET_MATERIAL_TRANSFERS_VEHICLES_ALL":
            return {
              ...state,
              vehicleList: action.data
            }

    case "GET_MATERIAL_TRANSFERS_MACHINERIESROUTERS_ALL":
      return {
        ...state,
        routerList: action.data
      }

            

  

    case "GET_MATERIAL_TRANSFER_OUTPUT_MACHINERY":
      return {
        ...state,
        materialList: action.data
      }

    case "GET_MATERIAL_TRANSFER_INPUT_MACHINERY":
      return {
        ...state,
        materialList: action.data
      }

    case "GET_MATERIAL_TRANSFER_CONFIG_ALL":
      return {
        ...state,
        configList: action.data
      }

    

      

      

        

      

    case "INIT_FORM_MATERIAL_TRANSFERS":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
        originList: [],
        destinationList: [],
      }

    case "SHOW_MATERIAL_TRANSFERS":
      return {...state,
        currentMaterialTransfert:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "ERROR_GET_MATERIAL_TRANSFERS":
      return {...state,...action}

    case "ERROR_DELETE_MATERIAL_TRANSFERS":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_MATERIAL_TRANSFERS":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_MATERIAL_TRANSFERS":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_MATERIAL_TRANSFERS":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_MATERIAL_TRANSFERS":
      return {
        ...state,
        sweet:null
      }
    case "HIDE_SWEET_MATERIAL_TRANSFERS":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "CLEAR_LIST_MATERIAL_TRANSFER_FORM":
      return {
        ...state,
        originList: [],
        destinationList: [],
        machineryList: [],
        materialList: [],
        vehicleList: [],
        routerList: [],
        configList: [],
        totals:{},
        materialTransfertPrices:[],
        materialTransfertPrice:{},
        price:0,
        priceOriginList: [],
        priceDestinationList: [],
      }
    
    case "GET_MATERIAL_TRANSFER_ALL_LIST_FORM" :  
      return {
        ...state,
        machineryList: action.machineryList,
        materialList: action.materialList,
        vehicleList: action.vehicleList,
        routerList: action.routerList,
      }

    case "GET_MATERIAL_TRANSFER_ALL_LIST_FILTER":
      return {
        ...state,
        originList: action.origins,
        destinationList: action.destinations,
        machineryList: action.machineryList,
        vehicleList: action.vehicleList,
      }
    /**
     * Loading
     * 
     */

    case "START_LOADING_MATERIAL_TRANSFERS_LIST":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }
  
    case "END_LOADING_MATERIAL_TRANSFERS_LIST":
      delete state.dataLoading[action.index];
      return {
        ...state
      }


    default:
      return state
  }
}

export default MaterialTransfersReducer
