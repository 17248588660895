
const initialState = {
  data: [],
  params: null,
  allData: [],
  totals: [],
  filteredData: [],
  totalPages: 0,
  totalRecords: 0,
  currentPage:0,
  rowsPerPage:0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentSale:false,
  showForm:false,
  sweetInfoDelete:false,
  listClients:[],
  listVehicles:[],
  listMaterials:[],
  listMachineries:[],
  listContracts:[],
  materialTypes:[],
  bussinessUnits:[],
  listPaymentMethods:[],
  listPrepaidSales:[],
  dataLoading:{}
}

const SalesReducer = (state = initialState, action) => {

  switch (action.type) {

    case "SHOW_SALE_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_SALE_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }

    case "GET_REPORT_SALES_DATA":
      return {
        ...state,
        data: action.data.data,
        totals: action.totals,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to],

        currentPage:action.currentPage,
        rowsPerPage:action.rowsPerPage,
        totalRecords:action.totalRecords,
      }

    case "GET_REPORT_SALES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totals: action.totals,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentSale:false
      }

      case "GET_REPORT_SALES_CLIENTS_ALL":
        return {
          ...state,
          listClients: action.data
        }

      case "GET_REPORT_SALES_VEHICLES_ALL":
        return {
          ...state,
          listVehicles: action.data
        }

      case "GET_REPORT_SALES_MATERIALS_ALL":
        return {
          ...state,
          listMaterials: action.data
        }

      case "GET_REPORT_SALES_MACHINERIES_ALL":
        return {
          ...state,
          listMachineries: action.data
        }

      case "GET_REPORT_SALES_CONTRACTS_ALL":
          return {
            ...state,
            listContracts: action.data
          }



      case "GET_SALE_CONTRACTS_ALL":
        return {
          ...state,
          listContracts: action.data
        }

      case "GET_REPORT_SALES_PAYMENT_METHODS_ALL":
        return {
          ...state,
          listPaymentMethods: action.data
        }







    case "INIT_FORM_SALE":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_SALE":
      return {...state,
        currentSale:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "ERROR_GET_SALE":
      return {...state,...action}

    case "ERROR_DELETE_SALE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_SALE":
      return {
        ...state,
        sweet:null
      }

    case "HIDE_SWEET_SALE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "CLEAN_REPORT_SALE_CONTRACT_LIST":
      return {
        ...state,
        sweet:null,
        listClients:[{}],
        listVehicles:[{}],
        listMaterials:[{}],
        listPaymentMethods:[{}],
        listPrepaidSales:[{}],
      }

    case "CLEAN_CONTRACT_LOADING":
      return {
        ...state,
        dataLoading:{}
      }

    case "GET_MATERIAL_TYPES_ALL":
      return {
        ...state,
        materialTypes: action.data
      }

    case "GET_MATERIAL_BUSSINESS_UNITS_ALL":
      return {
        ...state,
        bussinessUnits: action.data
      }

    case "GET_REPORT_SALES_PREPAID_SALE_ALL":
      return {
        ...state,
        listPrepaidSales: action.data
      }






    default:
      return state
  }
}

export default SalesReducer