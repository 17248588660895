const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentExpense:false,
  showForm:false,
  sweetInfoDelete:false,
  listTypesExpenses:[],
  listMachineries:[],
  listVehicles:[],
  listPaymentMethods:[],
  listBankAccounts:[],

}

const ExpensesReducer = (state = initialState, action) => {

  switch (action.type) {

    case "GET_EXPENSES_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_EXPENSES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentExpense:false
      }

      case "GET_TYPE_EXPENSES_ALL":
        return {
          ...state,
          listTypesExpenses: action.data
        }

      case "GET_EXPENSE_MACHINERIES_ALL":
        return {
          ...state,
          listMachineries: action.data
        }

      case "GET_EXPENSE_VEHICLES_ALL":
        return {
          ...state,
          listVehicles: action.data
        }

    case "INIT_FORM_EXPENSE":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_EXPENSE":
      return {...state,
        currentExpense:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "ERROR_GET_EXPENSE":
      return {...state,...action}

    case "ERROR_DELETE_EXPENSE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_EXPENSE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_EXPENSE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_EXPENSE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_EXPENSE":
      return {
        ...state,
        sweet:null
      }

    case "HIDE_SWEET_EXPENSE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "GET_EXPENSES_PAYMENT_TYPES_ALL":
      return {
        ...state,
        listPaymentMethods: action.data
      }

    case "GET_BANK_ACCOUNTS_ALL":
      return {
        ...state,
        listBankAccounts: action.data
      }

      

    default:
      return state
  }
}

export default ExpensesReducer
