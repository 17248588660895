import * as constans from '../../constants';

const defaulState = {
  userId:null,
  fullName:null,
  token:null,
  isLoggedIn:false
}

const userInfo = localStorage.getItem('USER_INFO');
const INITIAL_STATE = userInfo ? JSON.parse(userInfo) : defaulState;

export const singIn = (state = INITIAL_STATE, action) =>  {
  switch (action.type) {
    case constans.SET_USER_INFO:
      return { ...action.payload }
    case "RESET_LOGOUT":
      //console.log("GRACIAS DIOS NO ME DEJES SOLO...");
      return { ...defaulState }
      default:
        return state;
  }
}

export const login = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
    case "LOGIN_WITH_EMAIL": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_FB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_TWITTER": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GOOGLE": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_GITHUB": {
      return { ...state, values: action.payload }
    }
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT_WITH_FIREBASE": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}
