
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentCellar:false,
  showForm:false,
  sweetInfoDelete:false,
  cellarMaterialList:[],
  listMaterialMovements:[],
  listMovements:[],
  listKML:[],
}

const CellarsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CELLARS_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_CELLARS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentCellar:false
      }

      
    case "GET_CELLAR_MATERIALS_MOVEMENTS_ALL":
      return {
        ...state,
        listMaterialMovements: action.data
      }
    
    case "GET_CELLAR_KML":
      return {
        ...state,
        listKML: action.data
      }

      

    case "GET_CELLAR_MOVEMENTS_ALL":
      return {
        ...state,
        listMovements: action.data
      }

      

    case "HIDE_SWEET_CELLAR_INPUT_MATERIAL":
        return {
          ...state,
          sweet:null,
          sweetInfoDelete:false,
        }

    case "ERROR_ACTIVE_CELLAR_INPUT_MATERIAL":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_INACTIVE_CELLAR_INPUT_MATERIAL":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "GET_CELLAR_MATERIALS_ALL":
          return {
            ...state,
            listMaterials: action.data
          }

    

      

    

    case "GET_CELLARS_MATERIALS":
      return {
        ...state,
        cellarMaterialList: action.data
      }

    case "INIT_FORM_CELLAR":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_CELLAR":
      return {...state,
        currentCellar:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "ERROR_GET_CELLAR":
      return {...state,...action}

    case "ERROR_DELETE_CELLAR":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_CELLAR":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SAVED_CELLAR_MOVEMENTS":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SAVED_MATERIAL_MOVEMENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }
      

    

    case "ERROR_SAVED_CELLAR":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_CELLAR_MOVEMENTS":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_MATERIAL_MOVEMENT":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }


    case "SHOW_SWHEET_CELLAR":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_CELLAR":
      return {
        ...state,
        sweet:null
      }

      
    case "HIDE_SWHEET_CELLAR_MOVEMENTS":
        return {
          ...state,
          sweet:null,
          sweetInfoDelete:false,
        }

    case "HIDE_SWHEET_MATERIAL_MOVEMENT":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

        

    case "HIDE_SWHEET_CELLAR_MOVEMENTS":
          return {
            ...state,
            sweet:null,
            sweetInfoDelete:false,
          }

        

    case "HIDE_SWEET_CELLAR":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    case "HIDE_SHOW_CELLAR":
      return {
        ...state,
        showForm:false,
        sweet:null,
        currentCellar:false,
        sweetInfoDelete:false,
      }


    default:
      return state
  }
}

export default CellarsReducer
