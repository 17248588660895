import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"

import dataList from "./data-list/"

import usersList from "./users/"

import rolesList from "./roles/"

import materialsList from "./materials/"

import clientsList from "./clients/"

import vehiclesList from "./vehicles/"

import cellarsList from "./cellars/"

import materialTransfersList from "./materialTransfers/"

import machineriesList from "./machineries/"

import fuelsList from "./fuels/"

import maintenancesList from "./maintenances/"

import salesList from "./sales/"

import prepaidSalesList from "./prepaidSales/"

import bankAccountsList from "./bankAccounts/"

import contractsList from "./contracts/"

import expensesList from "./expenses/"

import homeDashboard from "./homeDashboard/"

import reportCashFlow from "./reports/cashflow/"

import reportSales from "./reports/sales/"

import reportContractSales from "./reports/contractSales/"

import reportContractIndicators from "./reports/contractIndicators/"

import reportExpenses from "./reports/expenses/"

import reportMachinery from "./reports/machinery/"

import reportContracts from "./reports/contracts/"

import reportCertificates from "./reports/certificates/"


const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList:dataList,
  usersList:usersList,
  rolesList:rolesList,
  materialsList:materialsList,
  clientsList:clientsList,
  vehiclesList:vehiclesList,
  cellarsList:cellarsList,
  materialTransfersList:materialTransfersList,
  machineriesList:machineriesList,
  fuelsList:fuelsList,
  maintenancesList:maintenancesList,
  salesList:salesList,
  prepaidSalesList:prepaidSalesList,
  bankAccountsList:bankAccountsList,
  contractsList:contractsList,
  expensesList:expensesList,
  homeDashboard:homeDashboard,
  reportSales:reportSales,
  reportCashFlow:reportCashFlow,
  reportContractSales:reportContractSales,
  reportContractIndicators:reportContractIndicators,
  reportExpenses:reportExpenses,
  reportMachinery:reportMachinery,
  reportContracts:reportContracts,
  reportCertificates:reportCertificates
})

export default rootReducer
