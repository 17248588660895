
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentMaterial:false,
  showForm:false,
  sweetInfoDelete:false,
  measurementUnits:[],
  bussinessUnits:[],
  materialTypes:[],
  taxes:[]
}

const MaterialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MATERIALS_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_MATERIALS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentMaterial:false
      }

    case "GET_MATERIAL_MEASUREMENTUNITS_ALL":
      return {
        ...state,
        measurementUnits: action.data
      }

    case "GET_MATERIAL_TYPES_ALL":
      return {
        ...state,
        materialTypes: action.data
      }

    case "GET_MATERIAL_BUSSINESS_UNITS_ALL":
      return {
        ...state,
        bussinessUnits: action.data
      }

    case "GET_MATERIAL_TAXES_ALL":
        return {
          ...state,
          taxes: action.data.taxes
        }

      

    case "INIT_FORM_MATERIAL":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_MATERIAL":
      return {...state,
        currentMaterial:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
        taxes:[]
      }
    
    case "ERROR_GET_MATERIAL":
      return {...state,...action}
    
    case "ERROR_DELETE_MATERIAL":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_MATERIAL":
      return {
        ...state,
        response:{...action.res},
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_MATERIAL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_MATERIAL":
      return {
        ...state,
        sweet:null
      }
    case "HIDE_SWEET_MATERIAL":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    
    default:
      return state
  }
}

export default MaterialsReducer