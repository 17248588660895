
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentFuel:false,
  showForm:false,
  sweetInfoDelete:false,
  listMachineries:[],
  listTypesFuel:[],
  listFuelSuppliers:[],
  listFuelCellars:[],
}

const FuelsReducer = (state = initialState, action) => {
  
  switch (action.type) {
    
    case "GET_FUELS_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_FUELS_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentFuel:false
      }

    case "GET_FUEL_MACHINERIES_ALL":
      return {
        ...state,
        listMachineries: action.data
      }

    case "GET_FUEL_TYPESFUEL_ALL":
      return {
        ...state,
        listTypesFuel: action.data
      }

    case "GET_FUEL_FUELSUPPLIERS_ALL":
      return {
        ...state,
        listFuelSuppliers: action.data
      }
    
    case "GET_FUEL_CELLAR_ALL":
      return {
        ...state,
        listFuelCellars: action.data
      }

      

    case "INIT_FORM_FUEL":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_FUEL":
      return {...state,
        currentFuel:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }
    
    case "ERROR_GET_FUEL":
      return {...state,...action}
    
    case "ERROR_DELETE_FUEL":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_FUEL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_FUEL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_FUEL":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_FUEL":
      return {
        ...state,
        sweet:null
      }
      
    case "HIDE_SWEET_FUEL":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    
    default:
      return state
  }
}

export default FuelsReducer