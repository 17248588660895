
const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentPrepaidSale:false,
  showForm:false,
  sweetInfoDelete:false,
  listClients:[],
  listPaymentMethods:[],
  dataLoading:{},
}

const prepaidSalesReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "SHOW_PREPAID_SALE_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading,
        showForm:false,
      }

    case "HIDE_PREPAID_SALE_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }
    
    case "GET_PREPAID_SALES_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to],
        
      }

    case "GET_PREPAID_SALES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        currentPrepaidSale:false
      }


      case "CLEAR_PREPAID_SALE_CLIENTS_ALL":
        return {
          ...state,
          listClients: []
        }
      
      case "CLEAR_PREPAID_SALE_PAYMENT_TYPES_ALL":
        return {
          ...state,
          listPaymentMethods: []
        }

      case "GET_PREPAID_SALE_CLIENTS_ALL":
        return {
          ...state,
          listClients: action.data
        }
      
      case "GET_PREPAID_SALE_PAYMENT_TYPES_ALL":
        return {
          ...state,
          listPaymentMethods: action.data
        }

    case "INIT_FORM_PREPAID_SALE":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_PREPAID_SALE":
      return {...state,
        currentPrepaidSale:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }
    
    case "ERROR_GET_PREPAID_SALE":
      return {...state,...action}
    
    
    case "ERROR_DELETE_PREPAID_SALE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_ACTIVE_PREPAID_SALE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "ERROR_INACTIVE_PREPAID_SALE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_PREPAID_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_PREPAID_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_PREPAID_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_PREPAID_SALE":
      return {
        ...state,
        sweet:null
      }
      
    case "HIDE_SWEET_PREPAID_SALE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    default:
      return state
  }
}

export default prepaidSalesReducer