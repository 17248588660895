// You can customize the theme with the help of this file

//Template config options

//Se obtienen los valores del theme desde el localstorare o se asignan los valores por defecto

const theme = localStorage.getItem('theme') || "light";

console.log("localStorage", localStorage.getItem('theme'));
console.log("theme", theme);

const themeConfig = {
  layout: "vertical", // options[String]: "vertical"(default), "horizontal"
  theme: theme, // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false, // options[Boolean]: true, false(default)
  navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
  footerType: "static", // options[String]: static(default) / sticky / hidden
  disableCustomizer: true, // options[Boolean]: true, false(default)
  hideScrollToTop: false, // options[Boolean]: true, false(default)
  menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
  direction: "ltr" // options[String] : ltr / rtl
}

export default themeConfig
