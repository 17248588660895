const initialState = {
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentMaintenance:false,
  showForm:false,
  sweetInfoDelete:false,
  listMachineries:[],
  listServicesMaintenance:[]
}

const MaintenancesReducer = (state = initialState, action) => {
  
  switch (action.type) {
    
    case "GET_MAINTENANCES_DATA":
      return {
        ...state,
        data: action.data.data,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_MAINTENANCES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentMaintenance:false
      }

    case "GET_MAINTENANCE_MACHINERIES_ALL":
      return {
        ...state,
        listMachineries: action.data
      }

    case "GET_MAINTENANCE_SERVICESMAINTENANCE_ALL":
      return {
        ...state,
        listServicesMaintenance: action.data
      }

    case "INIT_FORM_MAINTENANCE":
      return {
        ...state,
        res:action.res,
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_MAINTENANCE":
      return {...state,
        currentMaintenance:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }
    
    case "ERROR_GET_MAINTENANCE":
      return {...state,...action}
    
    case "ERROR_DELETE_MAINTENANCE":
      return {
        ...state,
        sweetInfoDelete:{...action.sweet}
      }

    case "SAVED_MAINTENANCE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "ERROR_SAVED_MAINTENANCE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "SHOW_SWHEET_MAINTENANCE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_MAINTENANCE":
      return {
        ...state,
        sweet:null
      }
      
    case "HIDE_SWEET_MAINTENANCE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }

    
    default:
      return state
  }
}

export default MaintenancesReducer