const initialState = {
  data: [],
  params: null,
  allData: [],
  totals: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  sortIndex: [],
  sweet:null,
  res:null,
  currentSale:false,
  showForm:false,
  sweetInfoDelete:false,
  listClients:[],
  listVehicles:[],
  listMaterials:[],
  listMachineries:[],
  listContracts:[],
  dataLoading:{}
}

const ContractSalesReducer = (state = initialState, action) => {
  
  switch (action.type) {

    case "SHOW_SALE_LOADING":
      const dataLoading = state.dataLoading;
      dataLoading[action.index] = action.data;
      return {
        ...state,
        dataLoading: dataLoading
      }

    case "HIDE_SALE_LOADING":
        delete state.dataLoading[action.index];
        return {
          ...state
        }
    
    case "GET_REPORT_CONTRACT_SALES_DATA":
      return {
        ...state,
        data: action.data.data,
        totals: action.totals,
        totalPages: action.totalPages,
        params: action.params,
        sortIndex: [action.data.from,action.data.to]
      }

    case "GET_REPORT_CONTRACT_SALES_ALL_DATA":
      return {
        ...state,
        allData: action.data.data,
        totals: action.totals,
        totalRecords: action.data.total,
        sortIndex: [action.data.from,action.data.to],
        showForm:false,
        currentSale:false
      }

      case "GET_REPORT_CONTRACT_SALES_CLIENTS_ALL":
        return {
          ...state,
          listClients: action.data
        }
      
      case "GET_REPORT_CONTRACT_SALES_VEHICLES_ALL":
        return {
          ...state,
          listVehicles: action.data
        }
      
      case "GET_REPORT_CONTRACT_SALES_MATERIALS_ALL":
        return {
          ...state,
          listMaterials: action.data
        }
      
      case "GET_REPORT_CONTRACT_SALES_MACHINERIES_ALL":
        return {
          ...state,
          listMachineries: action.data
        }

      case "GET_REPORT_CONTRACT_SALES_CONTRACTS_ALL":
        return {
          ...state,
          listContracts: action.data
        }

    case "SHOW_SALE":
      return {...state,
        currentSale:{...action.res.data},
        showForm:action.showForm,
        sweetInfoDelete:false,
      }

    case "SHOW_SWHEET_SALE":
      return {
        ...state,
        sweet:{ ...action.sweet}
      }

    case "HIDE_SWHEET_SALE":
      return {
        ...state,
        sweet:null
      }
      
    case "HIDE_SWEET_SALE":
      return {
        ...state,
        sweet:null,
        sweetInfoDelete:false,
      }
    
    default:
      return state
  }
}

export default ContractSalesReducer